import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const Impressum: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <Seo title="Impressum" />
    <div className="container">
      <div className="d-flex flex-column align-items-center text-center mt-5">
        <h1>Impressum</h1>
        <p>More than 10 years experience in fibre composites and metal.</p>
      </div>
      <div className="d-flex flex-column align-items-start mt-5">
      <p className="fw-bold">Betreiber und Kontakt:</p>
        <p className="m-0 p-0">Stephan Hirl Design</p>
        <p className="m-0 p-0">Mertsee 10</p>
        <p className="m-0 p-0">D-84326 Falkenberg</p>
        <p>USt-ID-Nr.: DE335794187</p>
        <p><a className="text-gray" href="mailto:info@hirlomat.com">info@hirlomat.com</a></p>
        <p>Verantwortlicher für journalistisch-redaktionelle Inhalte gemäß § 55 II RstV</p>
        <p className="m-0 p-0">Stephan Hirl, Mertsee 10, 84326 Falkenberg</p>
      </div>
    </div>
  </Layout>
)

export default Impressum

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
